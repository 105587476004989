<template>
  <b-list-group :class="tabClasses" style="font-size: 14px">
    <b-row cols="1">
      <b-col>
        <b-list-group-item v-for="team in teamList" :key="team.id" class="list-row">
          <Team :team="team"/>
        </b-list-group-item>
      </b-col>
    </b-row>
  </b-list-group>
</template>

<script>
import {mapGetters} from "vuex";
import Team from "@/components/lists/siteList/Team";

export default {
  name: "TeamTab",
  props: ['teamList'],
  components: {
    Team
  },
  computed: {
    ...mapGetters(['userThemeId']),
    tabClasses() {
      if(this.userThemeId === 2) {
        return ['mt-2']
      }
      return ['text-white', 'mt-2']
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
